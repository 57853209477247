import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router'
import Registropago from '../registropago/registropago'
import Reprogturno from '../reprogturno/reprogturno'
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { ForcedLogOutAuthAction } from "../../redux/actions/AuthAction";
import {formattedDate} from '../../lib/commonFunctions'

function Itemturno(props) {
    const { datos, logout } = props
    let history = useHistory();

    const [cargando, setCargando] = useState(false)
    const [isThereError, setisThereError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [redirigir, setRedirigir] = useState("")
    const [existeCobro, setExisteCobro] = useState(false)
    const [mostrarPagar, setMostrarPagar] = useState(false)
    const [mostrarReprog, setMostrarReprog] = useState(false)
    const [realizado, setRealizado] = useState(false)


    const setError = (mensaje) => {
        setisThereError(true)
        setErrorMessage(mensaje)
    }

    const unsetError = () => {
        setisThereError(false)
        setErrorMessage("")
    }

    useEffect(() => {

        if (datos.estado === "P" || datos.estado === "A" || datos.estado === "F" || datos.estado === "T") {
            setExisteCobro(true)
        }

    }, [datos])

    const handleRegistrarRealizacion = (id_turno) => {

        const registrarRealizacion = async () => {

            unsetError()
            setCargando(true)

            let url = 'https://marevitotal.reviturnos.com.ar/api/auth/regRealTur'

            const { user } = JSON.parse(localStorage.getItem("auth"))
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.access_token
            }

            const datos = {
                id_turno
            }

            fetch(url, { method: 'POST', headers, body: JSON.stringify(datos) })
                .then(async res => {

                    if (res.status === 200) {
                        const respuesta = await res.json();

                        if (respuesta) {
                            console.log(respuesta)
                            setCargando(false)
                            unsetError()
                            setRealizado(true);
                        }


                    } else {
                        if (res.status === 500) {
                            logout(history)
                        }
                        const error = await res.json();
                        setCargando(false)
                        setError(error.mensaje)

                    }

                })
                .catch((error) => {
                    setCargando(false)
                    setError(error.message)
                })

        }
        registrarRealizacion()

    }




    const handleVerTurno = () => {
        setRedirigir("/buscarturno?id_turno=" + datos.id)
    }

    return (
        <>
        { redirigir && <Redirect to={redirigir} />}
            <div onClick={handleVerTurno}  >
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <b>Datos del turno</b>
                            </div>
                            <div className="card-body">
                                <span ><b>Nombre:&nbsp;</b>{datos.datos.nombre}&nbsp;&nbsp;</span><br />
                                <span ><b>Fecha:&nbsp;</b>{formattedDate(datos.fecha)}&nbsp;&nbsp;</span><br />
                                <span ><b>Hora:&nbsp;</b>{datos.hora}&nbsp;&nbsp;</span><br />
                                <span ><b>Estado:&nbsp;</b>
                                    {
                                        datos.estado === "C" && 'Confirmado'
                                    }
                                    {
                                        datos.estado === "P" && 'Pago'
                                    }
                                    {
                                        datos.estado === "A" && 'Resultado - Apto'
                                    }
                                    {
                                        datos.estado === "K" && 'Resultado - Condicional'
                                    }
                                    {
                                        datos.estado === "F" && 'Resultado - Rechazado'
                                    }
                                    {
                                        datos.estado === "T" && 'Realizado'
                                    }
                                &nbsp;&nbsp;</span><br />
                                <span ><b>Vehiculo:&nbsp;</b>{datos.datos.tipo_vehiculo}&nbsp;&nbsp;</span><br />
                            </div>

                        </div>


                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <b>Datos del vehiculo</b>
                            </div>
                            <div className="card-body">
                                <span ><b>Dominio:&nbsp;</b>{datos.datos.dominio}&nbsp;&nbsp;</span><br />
                                <span ><b>Marca:&nbsp;</b>{datos.datos.marca}&nbsp;&nbsp;</span><br />
                                <span ><b>Modelo:&nbsp;</b>{datos.datos.modelo}&nbsp;&nbsp;</span><br />
                                <span ><b>Año:&nbsp;</b>{datos.datos.anio}&nbsp;&nbsp;</span><br />
                                <span ><b>Combustible:&nbsp;</b>{datos.datos.combustible}&nbsp;&nbsp;</span>
                            </div>
                        </div>
                    </div>
                </div>

                {existeCobro && datos.cobro ? <><br /><div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <b>Datos del pago</b>
                            </div>
                            <div className="card-body">
                                <span ><b>Fecha:&nbsp;</b>{datos.cobro.fecha}&nbsp;&nbsp;</span><br />
                                <span ><b>Monto:&nbsp;</b>${datos.cobro.monto}.-&nbsp;&nbsp;</span><br />
                                <span ><b>Metodo:&nbsp;</b>{datos.cobro.metodo}&nbsp;&nbsp;</span><br />
                                <span ><b>ID de pago:&nbsp;</b>{datos.cobro.nro_op}&nbsp;&nbsp;</span><br />
                            </div>
                        </div>

                    </div>
                </div>
                {
                    datos.estado==='P' && !realizado && (
                    <div style={{paddingTop: "20px"}}>

                        <button style={{float: "left"}} onClick={() => setMostrarReprog(!mostrarReprog)} className="btn btn-dark" type="button">Reprogramar turno</button>

                        <button style={{float: "right"}} disable={cargando} onClick={() => handleRegistrarRealizacion(datos.id)} className="btn btn-dark" type="button">Registrar realización</button>
                    </div>
                    )
                }

                    {
                        cargando && <div style={{ width: "100%", textAlign: "center", marginTop: "50px" }}>


                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    }
                    {
                        isThereError && <>
                            <div style={{ position: "relative" }} className="alert alert-danger" role="alert">
                                {errorMessage}
                            </div></>
                    }
                </>

                    : <><br />
                    <button onClick={() => setMostrarReprog(!mostrarReprog)} className="btn btn-dark" type="button">Reprogramar turno</button>
                    <br /><br /><button style={{ position: "relative", textAlign: "center" }} onClick={() => setMostrarPagar(!mostrarPagar)} className="btn btn-dark" type="button">Registrar pago</button></>
                }


            </div>

            {mostrarPagar && <><br /><Registropago id={datos.id} /></>}
            {mostrarReprog && <><br /><Reprogturno id_anterior={datos.id} vehiculo={datos.datos.tipo_vehiculo} /></>}

            

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: (history) => {
            dispatch(ForcedLogOutAuthAction(history));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Itemturno);
