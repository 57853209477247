import React, { useEffect, useState, useRef } from 'react'
import Listadoturnos from '../listadoturnos/listadoturnos'
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { ForcedLogOutAuthAction } from "../../redux/actions/AuthAction";
import { useReactToPrint } from 'react-to-print';
import DayList from '../DayList/DayList';


const generateRandomString = (num) => {

    let result1 = Math.random().toString(36).substring(0, num);

    return result1;
}

function Turnosdia(props) {

    let history = useHistory();
    const componentRef = useRef();

    const { logout } = props

    const [cargando, setCargando] = useState(false)
    const [isThereError, setisThereError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [refrescar, setRefrescar] = useState(1)
    const [turnos, setTurnos] = useState([])
    const [dia, setDia] = useState("")
    const [diasFuturos, setDiasFuturos] = useState("")
    const [show,setShow]=useState(false)

    const setError = (mensaje) => {
        setisThereError(true)
        setErrorMessage(mensaje)
    }

    const unsetError = () => {
        setisThereError(false)
        setErrorMessage("")
    }

    useEffect(() => {
        const obtenerTurnosDiaActual = async () => {

            unsetError()
            setCargando(true)

            let url = 'https://marevitotal.reviturnos.com.ar/api/auth/turDiaAct'

            const { user } = JSON.parse(localStorage.getItem("auth"))
            const headers = {
                'Authorization': 'Bearer ' + user.access_token
            }


            fetch(url, { method: 'GET', headers })
                .then(async res => {

                    if (res.status === 200) {
                        const respuesta = await res.json();

                        if (respuesta) {


                            setTurnos(respuesta.turnosDia)
                            setDiasFuturos(respuesta.diasFuturos)
                            setCargando(false)
                            unsetError()
                        }


                    } else {
                        if (res.status === 500) {
                            logout(history)
                        }

                        const error = await res.json();
                        setCargando(false)
                        setError(error.mensaje)

                    }

                })
                .catch((error) => {
                    setCargando(false)
                    setError(error.message)
                })
        }


        const obtenerTurnosDia = async () => {

            unsetError()
            setCargando(true)

            

            let url = 'https://marevitotal.reviturnos.com.ar/api/auth/turDiaFut?dia=' + dia

            const { user } = JSON.parse(localStorage.getItem("auth"))
            const headers = {
                'Authorization': 'Bearer ' + user.access_token
            }


            fetch(url, { method: 'GET', headers })
                .then(async res => {

                    if (res.status === 200) {
                        const respuesta = await res.json();

                        if (respuesta) {


                            setTurnos(respuesta)
                            setCargando(false)
                            unsetError()
                        }


                    } else {
                        if (res.status === 500) {
                            logout(history)
                        }

                        const error = await res.json();
                        setCargando(false)
                        setError(error.mensaje)

                    }

                })
                .catch((error) => {
                    setCargando(false)
                    setError(error.message)
                })
        }


        if (!dia) {
            obtenerTurnosDiaActual()
        } else {
            obtenerTurnosDia()
        }

    }, [refrescar, dia])

    const handleRefresh = () => {
        setTurnos([])
        setRefrescar(generateRandomString(10))
    }

    

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (<>
        <div style={{ position: "relative", minHeight: "600px" }}>

            <div className="printing-section" ref={componentRef}>
                <DayList turnos={ turnos} />
            </div>
                
            
            
            <span style={{ float: "right", border: "0 !important" }}><button className="btn btn-info" onClick={handlePrint}>Imprimir</button></span>
            <span style={{ float: "right", border: "0 !important", marginRight: "5px" }}><button className="btn btn-info" onClick={handleRefresh}>Actualizar</button></span>
            <h3>Turnos</h3>


            {diasFuturos &&

                <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">Dia:</label>
                    <select style={{ width: "200px" }} onChange={(e) => setDia(e.target.value)} className="form-control" id="exampleFormControlSelect1">
                        <option key={'desplegar'} >Seleccione día</option>
                        {/* <option>Default select</option> */}
                        {

                            diasFuturos.map((diaFuturo, indice) => {
                                return <option key={indice}>{diaFuturo.fecha}</option>
                            })

                        }
                    </select>
                </div>

            }


            <br /><br />
            {
                cargando && <div style={{ width: "100%", textAlign: "center", marginTop: "50px" }}>


                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }


            {
                isThereError && <>
                    <div style={{ position: "relative" }} className="alert alert-danger" role="alert">
                        {errorMessage}
                    </div></>
            }

            {turnos && <Listadoturnos turnos={turnos} />}

            

        </div >
    </>)
}


const mapStateToProps = (state) => {
    return {
        user: state,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: (history) => {
            dispatch(ForcedLogOutAuthAction(history));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Turnosdia);
